import { schema } from 'normalizr';

import { IAnswer, IChoice } from '@/interfaces';
import { IAssessmentRaw } from '@/interfaces/AssessmentRaw';
import { IChoiceRaw } from '@/interfaces/ChoiceRaw';
import { IQuestionRaw } from '@/interfaces/QuestionRaw';
import { IRiskRaw } from '@/interfaces/RiskRaw';
import { ITopicRaw } from '@/interfaces/TopicRaw';
import { ITranslation } from '@/interfaces/Translation';
import { Risk } from '@/types';

type MultipleChoiceValue = {
  [key: string]: { checked: boolean; textValue: string | null; numberValue: number | null };
};
type AnswerWithDetails = IAnswer & {
  booleanAnswer?: { value: boolean } | null;
  choiceAnswer?: IChoice | null;
  multipleChoiceAnswer?: { value: MultipleChoiceValue };
  numberAnswer?: { value: number } | null;
  textAnswer?: { value: string } | null;
};

// translations
const translationsProcessor = (value: IQuestionRaw | IChoiceRaw | ITopicRaw | IRiskRaw) => {
  let translations: ITranslation = {};
  translations = value.translations.reduce<ITranslation>((acc, item) => {
    return Object.assign(acc, { [item.language]: { txt: item.txt, note: item.note } });
  }, {});

  return { ...value, translations };
};

const risksProcessor = (value: IAssessmentRaw) => {
  const { assessmentRiskRelations, ...rest } = value;
  const risks = assessmentRiskRelations?.reduce((acc, item) => {
    let translations: ITranslation = {};
    translations = item.risk.translations.reduce<ITranslation>((acc, item) => {
      return Object.assign(acc, { [item.language]: { txt: item.txt, note: item.note } });
    }, {});

    acc.push({ ...item.risk, translations });
    return acc;
  }, [] as Risk[]);
  return { ...rest, risks };
};

type AnswerValues = {
  booleanValue: boolean | null;
  choiceValue: IChoice | null;
  multipleChoiceValue: MultipleChoiceValue | null;
  numberValue: number | null;
  textValue: string | null;
};

const answerValuesProcessor = (value: AnswerWithDetails) => {
  const answerValues: AnswerValues = Object.assign(
    {},
    {
      booleanValue: value.booleanAnswer ? value.booleanAnswer?.value : null,
      choiceValue: value.choiceAnswer ? value.choiceAnswer : null,
      multipleChoiceValue: value.multipleChoiceAnswer ? value.multipleChoiceAnswer?.value : null,
      numberValue: value.numberAnswer ? value.numberAnswer?.value : null,
      textValue: value.textAnswer ? value.textAnswer?.value : null,
    },
  );

  return { ...value, ...answerValues };
};

// entities
export const companySchema = new schema.Entity('companies');
export const adminCompanySchema = new schema.Entity('companies', { company: companySchema });
export const adminCompaniesSchema = new schema.Array(adminCompanySchema);
export const productSchema = new schema.Entity(
  'products',
  {},
  {
    processStrategy: translationsProcessor,
  },
);

export const esgManagementActivitySchema = new schema.Entity(
  'esgManagementActivities',
  {},
  {
    processStrategy: translationsProcessor,
  },
);
export const esgManagementActivitiesSchema = new schema.Array(esgManagementActivitySchema);

// assessments
export const assessmentRequestSchema = new schema.Entity('assessmentRequests', {
  company: companySchema,
  product: productSchema,
  esgManagementActivities: esgManagementActivitiesSchema,
});
export const assessmentRequestsSchema = new schema.Array(assessmentRequestSchema);

export const riskSchema = new schema.Entity(
  'risks',
  {},
  {
    processStrategy: translationsProcessor,
  },
);
export const risksSchema = new schema.Array(riskSchema);

export const assessmentSchema = new schema.Entity(
  'assessments',
  {
    assessmentRequest: assessmentRequestSchema,
    esgManagementActivities: esgManagementActivitiesSchema,
  },
  { processStrategy: risksProcessor },
);
export const assessmentsSchema = new schema.Array(assessmentSchema);

export const adminAssessmentRequestSchema = new schema.Entity('assessmentRequests', {
  company: companySchema,
  product: productSchema,
  esgManagementActivities: esgManagementActivitiesSchema,
});
export const adminAssessmentRequestsSchema = new schema.Array(adminAssessmentRequestSchema);
export const adminAssessmentSchema = new schema.Entity(
  'assessments',
  {
    assessmentRequest: adminAssessmentRequestSchema,
    esgManagementActivities: esgManagementActivitiesSchema,
  },
  { processStrategy: risksProcessor },
);
export const adminAssessmentsSchema = new schema.Array(adminAssessmentSchema);

// questions
export const topicSchema = new schema.Entity(
  'topics',
  {},
  { processStrategy: translationsProcessor },
);
export const topicsSchema = new schema.Array(topicSchema);

export const choiceSchema = new schema.Entity(
  'choices',
  {},
  {
    processStrategy: translationsProcessor,
  },
);
export const choicesSchema = new schema.Array(choiceSchema);

export const dropdownItemSchema = new schema.Entity(
  'dropdownItems',
  {},
  {
    processStrategy: translationsProcessor,
  },
);

export const dropdownItemsSchema = new schema.Array(dropdownItemSchema);

dropdownItemSchema.define({
  children: dropdownItemsSchema,
});

export const childQuestionSchema = new schema.Entity(
  'childQuestions',
  {
    choices: choicesSchema,
    dropdownItems: dropdownItemsSchema,
  },
  {
    processStrategy: translationsProcessor,
  },
);
export const childQuestionsSchema = new schema.Array(childQuestionSchema);
export const questionSchema = new schema.Entity(
  'questions',
  {
    choices: choicesSchema,
    childQuestions: childQuestionsSchema,
    dropdownItems: dropdownItemsSchema,
  },
  {
    processStrategy: translationsProcessor,
  },
);
export const questionsSchema = new schema.Array(questionSchema);

export const questionWithoutChildrenSchema = new schema.Entity(
  'questions',
  {
    choices: choicesSchema,
    dropdownItems: dropdownItemsSchema,
    topic: topicSchema,
  },
  {
    processStrategy: translationsProcessor,
  },
);
export const questionsWithoutChildrenSchema = new schema.Array(questionWithoutChildrenSchema);

// answers
export const answerSchema = new schema.Entity('answers');
export const answersSchema = new schema.Array(answerSchema);

export const answerWithDetailsSchema = new schema.Entity(
  'answers',
  {
    question: questionWithoutChildrenSchema,
  },
  {
    processStrategy: answerValuesProcessor,
  },
);
export const answersWithDetailsSchema = new schema.Array(answerWithDetailsSchema);

// assessment details
export const assessmentDetailsSchema = new schema.Entity('assessments', {
  assessmentRequest: assessmentRequestSchema,
  answers: answersWithDetailsSchema,
  esgManagementActivities: esgManagementActivitiesSchema,
});

export const adminAssessmentDetailsSchema = new schema.Entity('assessments', {
  assessmentRequest: adminAssessmentRequestSchema,
  answers: answersWithDetailsSchema,
  esgManagementActivities: esgManagementActivitiesSchema,
});

export const assessmentsDetailsSchema = new schema.Array(assessmentDetailsSchema);
export const adminAssessmentsDetailsSchema = new schema.Array(adminAssessmentDetailsSchema);

export const userSchema = new schema.Entity('users');
export const adminUserSchema = new schema.Entity('users', { user: userSchema });
export const adminUsersSchema = new schema.Array(adminUserSchema);

export const messageSchema = new schema.Entity(
  'messages',
  {},
  { processStrategy: translationsProcessor },
);
export const messagesSchema = new schema.Array(messageSchema);

export const businessActivitySchema = new schema.Entity(
  'businessActivities',
  {},
  { processStrategy: translationsProcessor },
);
export const businessActivitiesSchema = new schema.Array(businessActivitySchema);

export const processSchema = new schema.Entity(
  'processes',
  {},
  {
    processStrategy: translationsProcessor,
  },
);
export const processesSchema = new schema.Array(processSchema);

export const assessmentSummaryItemSchema = new schema.Entity(
  'assessmentSummary',
  {
    topics: topicsSchema,
    processes: processesSchema,
  },
  { idAttribute: 'area' },
);

export const assessmentSummarySchema = new schema.Array(assessmentSummaryItemSchema);
