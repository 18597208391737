import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { NormalizedSchema } from 'normalizr';

import { RootState } from '@/types/RootState';
import { TypeToKey } from '@/types';
import { IQuestion } from '@/interfaces';

import { clearCredentials } from '../auth/authSlice';
import { selectCurrentTopicId } from '../topics/topicsSlice';

type QuestionsState = {
  ids: string[] | undefined;
  entities: TypeToKey<IQuestion>;
  currentQuestionId: string | null;
  isSubmissionConfirmationOpen: boolean;
};

const initialState = {
  ids: [],
  entities: {
    dropdownItems: {},
    questions: {},
    childQuestions: {},
    choices: {},
  },
  currentQuestionId: null,
  isSubmissionConfirmationOpen: false,
} as QuestionsState;

const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IQuestion>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = payload.entities;
      state.currentQuestionId = null;
    },
    setCurrentQuestionId: (state, { payload }: PayloadAction<string>) => {
      state.currentQuestionId = payload;
    },
    setCurrentQuestionForTopic: (
      state,
      { payload }: PayloadAction<{ topicId: string; showLastQuestion?: boolean | null }>,
    ) => {
      if (!isEmpty(state.entities.questions)) {
        const questionsForTopic = Object.values(state.entities.questions)
          .filter((item) => item.topicId === payload.topicId)
          .sort((a, b) => a.sorting - b.sorting);
        if (payload.showLastQuestion) {
          state.currentQuestionId = questionsForTopic?.[questionsForTopic.length - 1]?.id;
        } else {
          state.currentQuestionId = questionsForTopic?.[0]?.id;
        }
      } else {
        state.currentQuestionId = null;
      }
    },
    setIsSubmissionConfirmationOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSubmissionConfirmationOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const {
  setQuestions,
  setCurrentQuestionId,
  setCurrentQuestionForTopic,
  setIsSubmissionConfirmationOpen,
} = slice.actions;

export default slice.reducer;

export const selectQuestionsState = (state: RootState) => state.questions;
export const selectQuestionIds = (state: RootState) => state.questions.ids;
export const selectQuestions = (state: RootState) => state.questions.entities.questions;
export const selectChoices = (state: RootState) => state.questions.entities.choices;
export const selectChildQuestions = (state: RootState) => state.questions.entities.childQuestions;
export const selectQuestionChoices = (state: RootState) => state.questions.entities.choices;
export const selectQuestionDropdownItems = (state: RootState) =>
  state.questions.entities.dropdownItems;
export const selectCurrentQuestion = (state: RootState) => {
  if (state.questions.currentQuestionId) {
    return state.questions.entities.questions[state.questions.currentQuestionId];
  }
  return null;
};
export const selectIsSubmissionConfirmationOpen = (state: RootState) =>
  state.questions.isSubmissionConfirmationOpen;

export const selectCurrentTopicQuestions = createSelector(
  selectCurrentTopicId,
  selectQuestions,
  (currentTopicId, questions) => {
    if (currentTopicId && questions) {
      return Object.keys(questions)
        .filter((key) => questions[key].topicId === currentTopicId)
        .reduce((acc: { [key: string]: IQuestion }, key) => {
          acc[key] = questions[key];
          return acc;
        }, {});
    }
    return {};
  },
);
