import type { ListenerEffectAPI, PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { isEmpty, merge } from 'lodash';
import { NormalizedSchema } from 'normalizr';

import { AppStartListening } from '@/app/middleware/listenerMiddleware';
import { answersSchema, assessmentsSchema } from '@/app/schema';
import { answersApi } from '@/app/services/answers';
import { usersApi } from '@/app/services/users';
import { IAnswer, IAssessment } from '@/interfaces';
import { IAnswerEdit } from '@/interfaces/AnswerEdit';
import { IAnswerError } from '@/interfaces/AnswerError';
import { TypeToKey } from '@/types';
import { AppDispatch } from '@/types/AppDispatch';
import { RootState } from '@/types/RootState';
import { prepareAnswerUpdates, validateAnswers } from '@/utils/answer';
import { normalizeResponse } from '@/utils/normalize';

import { setAssessments } from '../assessments/assessmentsSlice';
import { clearCredentials } from '../auth/authSlice';
import { changeLocation, showConfirmationDialog } from '../global/globalSlice';
import {
  selectQuestions,
  setCurrentQuestionId,
  setIsSubmissionConfirmationOpen,
} from '../questions/questionsSlice';
import { setCurrentTopicId } from '../topics/topicsSlice';

type WithPayloadType =
  | { topicId: string; showLastQuestion?: boolean | null }
  | { path: string; locale: string }
  | string
  | boolean;

export type AnswersState = {
  ids: string[] | [];
  entities: TypeToKey<IAnswer>;
  currentQuestionAnswers: { [key: string]: IAnswerEdit };
  hasUnsavedData: boolean;
  errors: IAnswerError;
  validationActive: boolean;
};

const initialState = {
  ids: [],
  entities: {},
  currentQuestionAnswers: {},
  hasUnsavedData: false,
} as AnswersState;

export const saveAnswer = createAction('answer/save');

const slice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    setAnswers: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IAnswer>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = payload.entities;
    },
    setCurrentQuestionAnswers: (
      state,
      { payload }: PayloadAction<{ [key: string]: IAnswerEdit }>,
    ) => {
      state.currentQuestionAnswers = payload;
    },
    setHasUnsavedData: (state, { payload }: PayloadAction<boolean>) => {
      state.hasUnsavedData = payload;
    },
    updateAnswers: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IAnswer>, string[]>>,
    ) => {
      const newState = merge({}, state, {
        entities: payload.entities,
        ids: payload.result,
      });

      // ENG-180 lodash's merge doesn't delete document from state that are missing from payload
      const payloadAnswerIds = Object.keys(payload.entities.answers);
      payloadAnswerIds.forEach((payloadAnswerId) => {
        newState.entities.answers[payloadAnswerId].documents =
          payload.entities.answers[payloadAnswerId].documents;
      });

      state.ids = newState.ids;
      state.entities = newState.entities;
    },
    deleteAnswers: (state, { payload }: PayloadAction<string[]>) => {
      const newAnswers = Object.assign({}, state.entities.answers);
      payload.forEach((answerId) => {
        delete newAnswers[answerId];
      });
      state.entities.answers = newAnswers;
      state.ids = state.ids.filter((id) => !payload.includes(id));
    },
    setErrors: (state, { payload }: PayloadAction<IAnswerError>) => {
      state.errors = payload;
    },
    setValidationActive: (state, { payload }: PayloadAction<boolean>) => {
      state.validationActive = payload;
    },
    navigateAway: (
      state,
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        payload,
      }: PayloadAction<{
        to: string;
        withPayload?: WithPayloadType;
      }>,
    ) => {
      state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearCredentials, () => {
        return initialState;
      })
      .addCase(saveAnswer, (state) => {
        return state;
      });
  },
});

export const {
  setAnswers,
  setCurrentQuestionAnswers,
  setHasUnsavedData,
  updateAnswers,
  deleteAnswers,
  setErrors,
  setValidationActive,
  navigateAway,
} = slice.actions;

export default slice.reducer;

export const selectAnswersState = (state: RootState) => state.answers;
export const selectAnswerIds = (state: RootState) => state.answers.ids;
export const selectAnswers = (state: RootState) => state.answers.entities.answers;
export const selectCurrentQuestionAnswers = (state: RootState) =>
  state.answers.currentQuestionAnswers;
export const selectHasUnsavedData = (state: RootState) => state.answers.hasUnsavedData;
export const selectErrors = (state: RootState) => state.answers.errors;
export const selectValidationActive = (state: RootState) => state.answers.validationActive;

export const selectAllQuestionsAnswered = createSelector(
  selectQuestions,
  selectAnswers,
  (questions, answers) => {
    let ret = true;

    const questionValues = !isEmpty(questions) ? Object.values(questions) : [];
    const answerValues = !isEmpty(answers) ? Object.values(answers) : [];

    if (questionValues.length === 0 || answerValues.length === 0) {
      return false;
    }

    for (const q of questionValues) {
      const answer = answerValues.find((a) => a.questionId === q.id);
      if (!answer) {
        ret = false;
      }
    }

    return ret;
  },
);

const callTargetAction = async (
  to: string,
  withPayload: WithPayloadType,
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
) => {
  if (to === 'topics/setCurrentTopicId') {
    listenerApi.dispatch(
      setCurrentTopicId(withPayload as { topicId: string; showLastQuestion?: boolean | null }),
    );
  } else if (to === 'questions/setCurrentQuestionId') {
    listenerApi.dispatch(setCurrentQuestionId(withPayload as string));
  } else if (to === 'questions/setIsSubmissionConfirmationOpen') {
    listenerApi.dispatch(setIsSubmissionConfirmationOpen(withPayload as boolean));
  } else if (to === 'auth/clearCredentials') {
    listenerApi.dispatch(usersApi.endpoints.logout.initiate());
    await listenerApi.take(usersApi.endpoints.logout.matchFulfilled);
    listenerApi.dispatch(clearCredentials());
    listenerApi.dispatch(changeLocation({ path: '/login', locale: withPayload as string }));
  } else if (to === 'global/changeLocation') {
    const payload = withPayload as { path: string; locale: string };
    if (payload.path === '/assessments') {
      listenerApi.dispatch(setAssessments(normalizeResponse<IAssessment>([], assessmentsSchema)));
      listenerApi.dispatch(setAnswers(normalizeResponse<IAnswer>([], answersSchema)));
    }
    listenerApi.dispatch(changeLocation(withPayload as { path: string; locale: string }));
  }
};

export const addAutosaveAnswersListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(navigateAway),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState();
      const { to, withPayload } = action.payload;

      if (state.answers.hasUnsavedData) {
        try {
          const errors = validateAnswers(
            state.questions.currentQuestionId
              ? state.questions.entities.questions[state.questions.currentQuestionId]
              : null,
            state.answers.currentQuestionAnswers,
            state.questions.entities.choices,
            state.questions.entities.childQuestions,
          );

          if (isEmpty(errors)) {
            const updates = prepareAnswerUpdates(
              state.questions.currentQuestionId
                ? state.questions.entities.questions[state.questions.currentQuestionId]
                : null,
              state.answers.currentQuestionAnswers,
              state.answers.entities.answers,
              state.questions.entities.childQuestions,
            );

            if (!isEmpty(updates)) {
              listenerApi.dispatch(answersApi.endpoints.createOrUpdateAnswers.initiate(updates));
              const [{ payload }] = await listenerApi.take(
                answersApi.endpoints.createOrUpdateAnswers.matchFulfilled,
              );
              listenerApi.dispatch(updateAnswers(payload));
              if (updates.delete && updates.delete.length > 0) {
                listenerApi.dispatch(deleteAnswers(updates.delete));
              }

              listenerApi.dispatch(setErrors({}));
              listenerApi.dispatch(setValidationActive(false));
              listenerApi.dispatch(setHasUnsavedData(false));

              await callTargetAction(to, withPayload, listenerApi);
            } else {
              listenerApi.dispatch(setHasUnsavedData(false));
              await callTargetAction(to, withPayload, listenerApi);
            }
          } else {
            listenerApi.dispatch(setErrors(errors));
            listenerApi.dispatch(setValidationActive(true));
            setTimeout(() => {
              const question = document.querySelector('.inputError')?.closest('.questionContainer');
              question?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 0);
            if (to !== 'questions/setIsSubmissionConfirmationOpen') {
              throw new Error('Answers not valid');
            }
          }
        } catch (error) {
          listenerApi.unsubscribe();
          listenerApi.dispatch(
            showConfirmationDialog({
              title: 'unsavedDataTitle',
              content: 'unsavedDataContent',
              cancelButtonText: 'unsavedDataCancelButtonText',
              confirmButtonText: 'unsavedDataConfirmButtonText',
              onConfirm: { type: to, payload: withPayload },
            }),
          );
          listenerApi.subscribe();
        }
      } else {
        await callTargetAction(to, withPayload, listenerApi);
        listenerApi.dispatch(setErrors({}));
        listenerApi.dispatch(setValidationActive(false));
      }
    },
  });
};
